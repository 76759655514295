// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap/dist/js/bootstrap.bundle.js'
window.bootstrap = require("bootstrap")

// ActionText
require("trix")
require("@rails/actiontext")

// import all stylesheets
import "stylesheets/application"

// customisations for the trix editor
import "../js/trix-editor-overrides.js"

// syntax highlighting for ActionText
import '../js/highlight/index.js'

// multi-select dropdowns
import '../js/slim-select.js'
