window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = [
      // images
      'image/jpeg', 'image/png', 'image/bmp', 'image/gif',
      // mp3 audio
      'audio/mpeg',
      // PDFs
      'application/pdf',
      // plain text
      'text/plain',
      // Microsoft Word
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/rtf',
      // Microsoft Excel
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
      // Microsoft Powerpoint
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint'
      ]
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Sorry, that file type isn't supported.\n\n We support these file types:\n" +
      "    * images (jpg, jpeg, gif, png, bmp)\n" +
      "    * audio files (mp3)\n" +
      "    * PDFs (pdf)\n" +
      "    * Plain text (txt)\n" +
      "    * Microsoft Word (doc, docx, rtf)\n" +
      "    * Microsoft Excel (xls, xlsx, csv)\n" +
      "    * Microsoft Powerpoint (ppt, pptx)")
  }
  const maxFileSize = 2 * 1024 * 1024 // 2Mb
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Sorry, that file is too large. We only support attachments up to 2Mb.\n\n" +
      "If you're trying to upload an image, try using the website imageresizer.com to make it smaller.")
  }
})
